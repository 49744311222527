import { template as template_9c49b8a416774cac9bb9d9b5b022607f } from "@ember/template-compiler";
const FKText = template_9c49b8a416774cac9bb9d9b5b022607f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
