import { template as template_f8961242b9c04083bfececd92f562275 } from "@ember/template-compiler";
const FKLabel = template_f8961242b9c04083bfececd92f562275(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
