import { template as template_1b2cb8824b7349efbb84a7dfd9ef2ed0 } from "@ember/template-compiler";
const SidebarSectionMessage = template_1b2cb8824b7349efbb84a7dfd9ef2ed0(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
